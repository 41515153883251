@import url('https://fonts.googleapis.com/css2?family=Alatsi&family=Inter:wght@300;400;500;600&display=swap');
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

#root{
  position: fixed;
  bottom: 11px;
  right: 34px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #babbb9;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #babbb9
}

.micBtn::after {
  content: '';
  position: absolute;
  border: 1px solid #babbb9;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  animation: ripple 0.5s infinite alternate-reverse;
  z-index: 0;
  
}

@keyframes ripple {
  0% {
    height: 10px;
    width: 10px;
    top:10px
  }
  100% {
    height: 150px;
    width: 150px;
    top:10px

  }
}
